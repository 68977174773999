import style from './Explorer.module.css'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import { Swipeable } from '../helpers/swipeable'
import { useState, useRef, useEffect, useContext } from 'react'
import { LayoutContext } from './sg/Layout'
import { useRouter } from 'next/router'
import Link from 'next/link'

export default function Explorer () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const frame = useRef()
  const router = useRouter()
  const { locale } = router
  const lang = locale.split('-')[0]
  const [loading, setLoading] = useState(true)
  const [explorerVisible, setExplorerVisible] = useState()

  const [layoutContext] = useContext(LayoutContext)
  const { currency, productUnit } = layoutContext

  useEffect(() => {
    frame.current.src = `https://cache-explorer.gramchain.net/?brand=gs&theme=2&unit=${productUnit}&currency=${currency}&lang=${lang}`
  }, [])

  useEffect(() => {
    document.body.style.overflow = explorerVisible ? 'hidden' : 'unset'
  }, [explorerVisible])

  return (
    <div className={c('explore')}>
      <h3>{t('explorer-header')}</h3>
      <div className={c('split', { visible: explorerVisible })}>
        <div>
          <ul>
            <li>
              <div>
                <img src='/images/goldstrom-auditable.png' width={48} height={48} alt={t('explorer-publiclyauditable-title')} />
                <h4>{t('explorer-publiclyauditable-title')}</h4>
              </div>
              <p>
                {t('explorer-publiclyauditable-body')}
                <button className={c('explorebutton')} onClick={() => setExplorerVisible(true)}>{t('explorer-open')}</button>
              </p>
            </li>
            <li>
              <div>
                <img src='/images/goldstrom-goldbacked.png' width={48} height={48} alt={t('explorer-flexible-title')} />
                <h4>{t('explorer-backedbyrealgold-title')}</h4>
              </div>
              <p>{t('explorer-backedbyrealgold-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/redemption.svg' width={48} height={48} alt={t('explorer-redeemable-title')} />
                <h4>{t('explorer-redeemable-title')}</h4>
              </div>
              <p>{t('explorer-redeemable-body')}</p>
            </li>
            <li>
              <div>
                <img src='/images/goldstrom-lowfees.png' width={48} height={48} alt={t('explorer-lowfees-title')} />
                <h4>{t('explorer-lowfees-title')}</h4>
              </div>
              <p>{t('explorer-lowfees-body')} <Link href='/sg/pricing'><a>{t('explorer-lowfees-link')}</a></Link></p>
            </li>
          </ul>
        </div>
        <div className={c('exploreembed', { loading })}>
          <Swipeable onSwipedDown={() => setExplorerVisible(false)} className={c('header')} onClick={() => setExplorerVisible(false)} />
          <div className={c('wrapper')}><iframe ref={frame} loading='lazy' onLoad={() => setLoading(false)} /></div>
        </div>
      </div>
    </div>
  )
}
